.jeuxCarre {
    box-sizing: border-box;
    width: 100%;
    height: 500px;
    border: 2px solid gray;
    overflow: hidden;
}

.width100 {
    width: 100%;
}

.absolute {
    position: absolute;
}

.virusMoove {
    width: 50px;
}

.virusMoove:hover {
    opacity: 0.7;
    cursor: pointer;
}

.un {
    animation: 7s linear forwards moove1;
}

.good {
    box-sizing: border-box;
    border: 4px solid green;
    background-color: white;
    color: green;
}

.bleu {
    box-sizing: border-box;
    border: 4px solid darkblue;
    background-color: white;
    color: darkblue;
}

.erreur {
    box-sizing: border-box;
    border: 4px solid red;
    background-color: white;
    color: red;
}

.pad8-18 {
    padding: 8px 18px;
}

.width40 {
    width: 40%;
}

.goodOrange {
    box-sizing: border-box;
    border: 4px solid dodgerblue;
    background-color: white;
    color: dodgerblue;
}

.un {
    animation: 10s linear forwards moove1;
}

.borderCorail {
    border: 4px solid coral;
    border-radius: 5px 5px 0 0;
}

.borderCorailRadius {
    border-radius: 0px 0px 5px 5px;
}

@keyframes moove1 {
    0% {
    }
    99% {
        opacity: 0.9;
        width: 50px;
    }

    100% {
        opacity: 0;
        width: 0px;
        bottom: 500px;

    }
}

.deux {
    animation: 10s linear forwards moove2;
}

@keyframes moove2 {
    0% {
    }
    99% {
        opacity: 0.9;
        width: 50px;
    }

    100% {
        opacity: 0;
        width: 0px;
        top: 500px;

    }
}

.trois {
    animation: 10s linear forwards moove3;
}

@keyframes moove3 {
    0% {
    }
    99% {
        opacity: 0.9;
        width: 50px;
    }

    100% {
        opacity: 0;
        width: 0px;
        left: -500px;

    }
}

.quatre {
    animation: 10s linear forwards moove4;
}

@keyframes moove4 {
    0% {
    }
    99% {
        opacity: 0.9;
        width: 50px;
    }

    100% {
        opacity: 0;
        width: 0px;
        left: 500px;

    }
}